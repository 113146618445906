import i18next from "i18next";
import { NotificationTypes } from "../enums/notificationTypes";

export function getLocalizedError(
    errorMessage: string,
    status?: number
): { type: NotificationTypes; message: string; description: string } {
    if (status != null && status === 501) {
        return {
            type: NotificationTypes.info,
            message: i18next.t("common.notification"),
            description: i18next.t("common.inDev"),
        };
    } else if (errorMessage.length < 300 && !errorMessage.startsWith("TypeError")) {
        return {
            type: NotificationTypes.error,
            message: i18next.t("notifications.error"),
            description: errorMessage,
        };
    } else {
        return {
            type: NotificationTypes.error,
            message: i18next.t("errors.error"),
            description: i18next.t("errors.unknown"),
        };
    }
}
