import { IBusesState, BusesInitialState } from "./busesState";
import { reducerWithInitialState } from "typescript-fsa-reducers";
import { Failure, Success } from "typescript-fsa";
import { newState } from "../../common/newState";
import { IEmpty } from "../../common/empty";
import { BusesListResponse } from "../../core/api/dto/BusesListResponse.g";
import { BusesActions } from "./busesActions";
import { SuccessResponse } from "../../core/api/dto/SuccessResponse.g";
import { DeleteBusRequest } from "../../core/api/dto/DeleteBusRequest";
import { Bus } from "../../core/api/dto/Bus.g";
import { AddBusRequest } from "../../core/api/dto/AddBusRequest.g";
import { EditBusRequest } from "../../core/api/dto/EditBusRequest.g";
import { ChangeBusStateRequest } from "../../core/api/dto/ChangeBusStateRequest.g";
import { TransferBusRequest } from "../../core/api/dto/TransferBusRequest.g";
import { BusRequest } from "../../core/api/dto/BusRequest.g";
import { SetToRouteRequest } from "../../core/api/dto/SetToRouteRequest.g";

function loadingStarted(state: IBusesState): IBusesState {
    return newState(state, { isLoading: true });
}

// function loadingEnded(state: IBusesState): IBusesState {
//   return newState(state, { isLoading: false });
// }

function loadingFailed(state: IBusesState, failed: Failure<IEmpty, Error>): IBusesState {
    return newState(state, { isLoading: false, error: failed.error.message });
}

function loadingBusesDone(
    state: IBusesState,
    { result }: Success<IEmpty, BusesListResponse>
): IBusesState {
    return newState(state, { isLoading: false, list: result });
}

function loadingBusDone(state: IBusesState, { result }: Success<BusRequest, Bus>): IBusesState {
    return newState(state, { isLoading: false, bus: result });
}

function deletingDone(
    state: IBusesState,
    { params }: Success<DeleteBusRequest, SuccessResponse>
): IBusesState {
    const busesList = [...state.list.data];
    const busIndex = busesList.findIndex((bus) => bus.id === params.id);

    if (busIndex !== -1) {
        busesList.splice(busIndex, 1);
    }

    return newState(state, {
        isLoading: false,
        list: { ...state.list, data: busesList },
    });
}

function addingDone(state: IBusesState, { result }: Success<AddBusRequest, Bus>): IBusesState {
    const busesList = [...state.list.data];
    busesList.push(result);

    return newState(state, {
        isLoading: false,
        list: { ...state.list, data: busesList },
    });
}

function editingDone(
    state: IBusesState,
    { params, result }: Success<EditBusRequest, Bus>
): IBusesState {
    const busesList = [...state.list.data];
    const busIndex = busesList.findIndex((item) => item.id === params.id);

    if (busIndex !== -1) {
        busesList.splice(busIndex, 1, result);
    }

    return newState(state, {
        isLoading: false,
        list: { ...state.list, data: busesList },
    });
}

function changingStateDone(
    state: IBusesState,
    { params, result }: Success<ChangeBusStateRequest, Bus>
): IBusesState {
    const busesList = [...state.list.data];
    const busIndex = busesList.findIndex((item) => item.id === params.id);

    if (busIndex !== -1) {
        busesList.splice(busIndex, 1, result);
    }

    return newState(state, {
        isLoading: false,
        list: { ...state.list, data: busesList },
    });
}

function transferingDone(
    state: IBusesState,
    { params, result }: Success<TransferBusRequest, Bus>
): IBusesState {
    const busesList = [...state.list.data];
    const busIndex = busesList.findIndex((item) => item.id === params.busId);

    if (busIndex !== -1) {
        busesList.splice(busIndex, 1);
        busesList.push(result);
    }

    return newState(state, {
        isLoading: false,
        list: { ...state.list, data: busesList },
    });
}

function settingToRouteDone(
    state: IBusesState,
    { params, result }: Success<SetToRouteRequest, Bus>
): IBusesState {
    const busesList = [...state.list.data];
    const busIndex = busesList.findIndex((item) => item.id === params.busId);

    if (busIndex !== -1) {
        busesList.splice(busIndex, 1, result);
    }

    return newState(state, {
        isLoading: false,
        list: { ...state.list, data: busesList },
    });
}

export const busesReducer = reducerWithInitialState(BusesInitialState)
    .case(BusesActions.getBuses.started, loadingStarted)
    .case(BusesActions.getBuses.done, loadingBusesDone)
    .case(BusesActions.getBuses.failed, loadingFailed)

    .case(BusesActions.getBus.started, loadingStarted)
    .case(BusesActions.getBus.done, loadingBusDone)
    .case(BusesActions.getBus.failed, loadingFailed)

    .case(BusesActions.delete.started, loadingStarted)
    .case(BusesActions.delete.failed, loadingFailed)
    .case(BusesActions.delete.done, deletingDone)

    .case(BusesActions.add.started, loadingStarted)
    .case(BusesActions.add.failed, loadingFailed)
    .case(BusesActions.add.done, addingDone)

    .case(BusesActions.edit.started, loadingStarted)
    .case(BusesActions.edit.failed, loadingFailed)
    .case(BusesActions.edit.done, editingDone)

    .case(BusesActions.changeBusState.started, loadingStarted)
    .case(BusesActions.changeBusState.failed, loadingFailed)
    .case(BusesActions.changeBusState.done, changingStateDone)

    .case(BusesActions.transfer.started, loadingStarted)
    .case(BusesActions.transfer.failed, loadingFailed)
    .case(BusesActions.transfer.done, transferingDone)

    .case(BusesActions.setToRoute.started, loadingStarted)
    .case(BusesActions.setToRoute.failed, loadingFailed)
    .case(BusesActions.setToRoute.done, settingToRouteDone);
