import { actionCreator } from "../../core/store/actionCreator";
import { ScheduleRoundsListRequest } from "../../core/api/dto/ScheduleRoundsListRequest.g";
import { PagedResponse } from "../../core/api/dto/PagedResponse.g";
import { ScheduleRoundItem } from "../../core/api/dto/ScheduleRoundItem.g";
import { ScheduleRoundAddRequest } from "../../core/api/dto/ScheduleRoundAddRequest.g";
import { ScheduleRoundEditRequest } from "../../core/api/dto/ScheduleRoundEditRequest.g";
import { ScheduleRoundsGetRequest } from "../../core/api/dto/ScheduleRoundsGetRequest.g";

export class SchedulesActions {
    static list = actionCreator.async<
        ScheduleRoundsListRequest,
        PagedResponse<ScheduleRoundItem>,
        Error
    >("Schedules/LIST");
    static add = actionCreator.async<ScheduleRoundAddRequest, ScheduleRoundItem, Error>(
        "Schedules/ADD"
    );
    static edit = actionCreator.async<ScheduleRoundEditRequest, ScheduleRoundItem, Error>(
        "Schedules/EDIT"
    );
    static get = actionCreator.async<ScheduleRoundsGetRequest, ScheduleRoundItem, Error>(
        "Schedules/GET"
    );
}
