import { reducerWithInitialState } from "typescript-fsa-reducers";
import { Failure, Success } from "typescript-fsa";
import { newState } from "../../common/newState";
import { CoreActions } from "../../core/store/coreActions";
import { IEmpty } from "../../common/empty";
import { IAppState } from "../../core/store/appState";
import { IRoundsState, RoundsInitialState } from "./roundsState";
import { RoundsActions } from "./roundsActions";
import { Round } from "../../core/api/dto/Round.g";
import { RoundsAddRequest } from "../../core/api/dto/RoundsAddRequest.g";
import { RoundsGetRequest } from "../../core/api/dto/RoundsGetRequest.g";
import { RoundEditRequest } from "../../core/api/dto/RoundEditRequest.g";
import { RoundsGetByDirectionRequest } from "../../core/api/dto/RoundsGetByDirectionRequest.g";

function addById(rounds: Map<string, Round[]>, id: string, round: Round): Map<string, Round[]> {
    const result = new Map(rounds);
    const roundsItemArray = rounds.get(id);
    let roundsResult: Round[] = [];

    if (roundsItemArray != null) {
        roundsResult = [...roundsItemArray];
        roundsResult.push(round);
    } else {
        roundsResult = [round];
    }

    result.set(id, roundsResult);

    return result;
}

function commonGetById(
    rounds: Map<string, Round[]>,
    id: string,
    nextRounds: Round[]
): Map<string, Round[]> {
    const result = new Map(rounds);
    result.set(id, nextRounds);

    return result;
}

function rehydrateHandler(state: IRoundsState, rehydratedState: IAppState): IRoundsState {
    return newState(state, {
        error: null,
        isLoading: false,
        rounds: new Map(),
    });
}

function commonStarted(state: IRoundsState): IRoundsState {
    return newState(state, { error: null, isLoading: true });
}

function commonFailed(state: IRoundsState, failed: Failure<IEmpty, Error>): IRoundsState {
    return newState(state, { error: failed.error.message, isLoading: false });
}

function editDone(
    state: IRoundsState,
    { params, result }: Success<RoundEditRequest, Round>
): IRoundsState {
    const rounds = new Map(state.rounds);
    let roundsResult = rounds.get(params.scheduleId);

    if (roundsResult != null) {
        const roundsIndex = roundsResult.findIndex((item) => item.id === result.id);
        if (roundsIndex !== -1) {
            roundsResult[roundsIndex] = result;
        } else {
            roundsResult.push(result);
        }
    } else {
        roundsResult = [result];
    }

    rounds.set(params.scheduleId, roundsResult);

    return newState(state, { error: null, isLoading: false, rounds });
}

function addDone(
    state: IRoundsState,
    { params, result }: Success<RoundsAddRequest, Round>
): IRoundsState {
    return newState(state, {
        error: null,
        isLoading: false,
        rounds: addById(state.rounds, params.scheduleRoundId.toString(), result),
    });
}

function getDone(
    state: IRoundsState,
    { params, result }: Success<RoundsGetRequest, Round[]>
): IRoundsState {
    return newState(state, {
        error: null,
        isLoading: false,
        rounds: commonGetById(state.rounds, params.id, result),
    });
}

function getByDirectionDone(
    state: IRoundsState,
    { params, result }: Success<RoundsGetByDirectionRequest, Round[]>
): IRoundsState {
    return newState(state, {
        error: null,
        isLoading: false,
        rounds: commonGetById(state.rounds, params.scheduleRoundId.toString(), result),
    });
}

export const roundsReducer = reducerWithInitialState(RoundsInitialState)
    .case(CoreActions.rehydrate, rehydrateHandler)

    .case(RoundsActions.add.started, commonStarted)
    .case(RoundsActions.add.done, addDone)
    .case(RoundsActions.add.failed, commonFailed)

    .case(RoundsActions.edit.started, commonStarted)
    .case(RoundsActions.edit.done, editDone)
    .case(RoundsActions.edit.failed, commonFailed)

    .case(RoundsActions.getByDirection.started, commonStarted)
    .case(RoundsActions.getByDirection.done, getByDirectionDone)
    .case(RoundsActions.getByDirection.failed, commonFailed)

    .case(RoundsActions.get.started, commonStarted)
    .case(RoundsActions.get.done, getDone)
    .case(RoundsActions.get.failed, commonFailed);
