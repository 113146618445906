import { applyMiddleware } from "redux";
import { createLogger, ReduxLoggerOptions } from "redux-logger";
import { ReduxStack } from "redux-stack";
import { CoreActions } from "../coreActions";

const options: ReduxLoggerOptions = {
    diff: true,
    collapsed: true,
    predicate: (getState: () => any, action: any): boolean => {
        return action.type !== CoreActions.rehydrate && process.env.NODE_ENV === "development";
    },
};
const logger = createLogger(options);

export const reduxLoggerInit: ReduxStack = {
    enhancers: process.env.NODE_ENV === "development" ? [applyMiddleware(logger)] : [],
};
