import { createTransform } from "redux-persist";
import { IRoundsState } from "./roundsState";

export const roundsStateTransform = createTransform(
    (state: IRoundsState) => {
        return {
            ...state,
            rounds: Array.from(state.rounds) as any,
        };
    },
    (storedState: IRoundsState) => {
        if (storedState) {
            return {
                ...storedState,
                rounds: new Map(storedState.rounds),
            };
        } else {
            return storedState;
        }
    },
    {
        whitelist: ["rounds"],
    }
);
