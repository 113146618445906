import { reducerWithInitialState } from "typescript-fsa-reducers";
import { Failure, Success } from "typescript-fsa";
import { newState } from "../../common/newState";
import { CoreActions } from "../../core/store/coreActions";
import { IEmpty } from "../../common/empty";
import { IAppState } from "../../core/store/appState";
import { ISchedulesState, ScheduledInitialState } from "./schedulesState";
import { SchedulesActions } from "./schedulesActions";
import { PagedResponse } from "../../core/api/dto/PagedResponse.g";
import { ScheduleRoundItem } from "../../core/api/dto/ScheduleRoundItem.g";

function rehydrateHandler(state: ISchedulesState, rehydratedState: IAppState): ISchedulesState {
    return newState(state, {
        error: null,
        isLoading: false,
        scheduleRounds: { data: [], totalCount: 0, pageSize: 0, page: 0 },
    });
}

function listStarted(state: ISchedulesState): ISchedulesState {
    return newState(state, { error: null, isLoading: true });
}

function listDone(
    state: ISchedulesState,
    { result }: Success<IEmpty, PagedResponse<ScheduleRoundItem>>
): ISchedulesState {
    return newState(state, { error: null, isLoading: false, scheduleRounds: result });
}

function listFailed(state: ISchedulesState, failed: Failure<IEmpty, Error>): ISchedulesState {
    return newState(state, { error: failed.error.message, isLoading: false });
}

function addStarted(state: ISchedulesState): ISchedulesState {
    return newState(state, { error: null, isLoading: true });
}

function addDone(
    state: ISchedulesState,
    { result }: Success<IEmpty, ScheduleRoundItem>
): ISchedulesState {
    const scheduleRounds = { ...state.scheduleRounds };
    const data = [...scheduleRounds.data];
    data.unshift(result);
    scheduleRounds.data = data;

    return newState(state, { error: null, isLoading: false, scheduleRounds });
}

function addFailed(state: ISchedulesState, failed: Failure<IEmpty, Error>): ISchedulesState {
    return newState(state, { error: failed.error.message, isLoading: false });
}

function editStarted(state: ISchedulesState): ISchedulesState {
    return newState(state, { error: null, isLoading: true });
}

function editDone(
    state: ISchedulesState,
    { result }: Success<IEmpty, ScheduleRoundItem>
): ISchedulesState {
    const scheduleRounds = { ...state.scheduleRounds };
    const data = [...scheduleRounds.data];
    const roundItemIndex = data.findIndex((item) => item.id === result.id);

    if (roundItemIndex !== -1) {
        data[roundItemIndex] = result;
    }

    scheduleRounds.data = data;

    return newState(state, { error: null, isLoading: false, scheduleRounds });
}

function editFailed(state: ISchedulesState, failed: Failure<IEmpty, Error>): ISchedulesState {
    return newState(state, { error: failed.error.message, isLoading: false });
}

function getStarted(state: ISchedulesState): ISchedulesState {
    return newState(state, { error: null, isLoading: true });
}

function getDone(
    state: ISchedulesState,
    { result }: Success<IEmpty, ScheduleRoundItem>
): ISchedulesState {
    const scheduleRounds = { ...state.scheduleRounds };
    const data = [...scheduleRounds.data];
    const roundItemIndex = data.findIndex((item) => item.id === result.id);

    if (roundItemIndex !== -1) {
        data[roundItemIndex] = result;
    } else {
        data.unshift(result);
    }

    scheduleRounds.data = data;

    return newState(state, { error: null, isLoading: false, scheduleRounds });
}

function getFailed(state: ISchedulesState, failed: Failure<IEmpty, Error>): ISchedulesState {
    return newState(state, { error: failed.error.message, isLoading: false });
}

export const schedulesReducer = reducerWithInitialState(ScheduledInitialState)
    .case(CoreActions.rehydrate, rehydrateHandler)

    .case(SchedulesActions.list.started, listStarted)
    .case(SchedulesActions.list.done, listDone)
    .case(SchedulesActions.list.failed, listFailed)

    .case(SchedulesActions.add.started, addStarted)
    .case(SchedulesActions.add.done, addDone)
    .case(SchedulesActions.add.failed, addFailed)

    .case(SchedulesActions.edit.started, editStarted)
    .case(SchedulesActions.edit.done, editDone)
    .case(SchedulesActions.edit.failed, editFailed)

    .case(SchedulesActions.get.started, getStarted)
    .case(SchedulesActions.get.done, getDone)
    .case(SchedulesActions.get.failed, getFailed);
