import { AuthInitialState, IAuthState } from "./authState";
import { reducerWithInitialState } from "typescript-fsa-reducers";
import { AuthActions } from "./authActions";
import { Failure, Success } from "typescript-fsa";
import { newState } from "../../common/newState";
import { CoreActions } from "../../core/store/coreActions";
import { IEmpty } from "../../common/empty";
import { IAppState } from "../../core/store/appState";
import { LoginResponse } from "../../core/api/dto/LoginResponse.g";

function rehydrateHandler(state: IAuthState, rehydratedState: IAppState): IAuthState {
    return newState(state, {
        error: null,
        isAuthenticating: false,
        token: rehydratedState?.auth?.token,
    });
}

function loginStarted(state: IAuthState): IAuthState {
    return newState(state, { error: null, isAuthenticating: true });
}

function loginDone(state: IAuthState, { result }: Success<IEmpty, LoginResponse>): IAuthState {
    return newState(state, { error: null, isAuthenticating: false, token: result.access_token });
}

function loginFailed(state: IAuthState, failed: Failure<IEmpty, Error>): IAuthState {
    return newState(state, { error: failed.error.message, isAuthenticating: false });
}

export const authReducer = reducerWithInitialState(AuthInitialState)
    .case(CoreActions.rehydrate, rehydrateHandler)
    .case(AuthActions.login.started, loginStarted)
    .case(AuthActions.login.done, loginDone)
    .case(AuthActions.login.failed, loginFailed);
