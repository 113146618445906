import { actionCreator } from "../../core/store/actionCreator";
import { TransactionsShortListRequest } from "../../core/api/dto/TransactionsShortListRequest.g";
import { TransactionsDetailedListRequest } from "../../core/api/dto/TransactionsDetailedListRequest.g";
import { TransactionsGroupType } from "../../core/api/dto/TransactionsGroupType.g";
import { PagedResponse } from "../../core/api/dto/PagedResponse.g";
import { TransactionsDetailedItem } from "../../core/api/dto/TransactionsDetailedItem.g";
import { TransactionsShortItem } from "../../core/api/dto/TransactionsShortItem.g";

export class TransactionsActions {
    static getShortList = actionCreator.async<
        TransactionsShortListRequest,
        PagedResponse<TransactionsShortItem>,
        Error
    >("Transactions/GET_SHORT_LIST");

    static getDetailedList = actionCreator.async<
        TransactionsDetailedListRequest,
        PagedResponse<TransactionsDetailedItem>,
        Error
    >("Transactions/GET_DETAILED_LIST");

    static getGroupTypes = actionCreator.async<void, TransactionsGroupType[], Error>(
        "Transactions/GET_GROUP_TYPES"
    );
}
