import { NotificationTypes } from "../enums/notificationTypes";
import { toast } from "react-toastify";

export function showNotification(
    type: NotificationTypes,
    message?: string,
    description?: string
): void {
    toast(description !== undefined ? message + ": " + description : message, {
        type: type,
        position: "top-right",
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
}
