import { reducerWithInitialState } from "typescript-fsa-reducers";
import { Failure, Success } from "typescript-fsa";
import { newState } from "../../common/newState";
import { CoreActions } from "../../core/store/coreActions";
import { IEmpty } from "../../common/empty";
import { ITransactionsState, TransactionsInitialState } from "./transactionsState";
import { TransactionsActions } from "./transactionsActions";
import { TransactionsShortListRequest } from "../../core/api/dto/TransactionsShortListRequest.g";
import { TransactionsDetailedListRequest } from "../../core/api/dto/TransactionsDetailedListRequest.g";
import { TransactionsGroupType } from "../../core/api/dto/TransactionsGroupType.g";
import { IAppState } from "../../core/store/appState";
import { PagedResponse } from "../../core/api/dto/PagedResponse.g";
import { TransactionsDetailedItem } from "../../core/api/dto/TransactionsDetailedItem.g";
import { TransactionsShortItem } from "../../core/api/dto/TransactionsShortItem.g";

function rehydrateHandler(
    state: ITransactionsState,
    rehydratedState: IAppState
): ITransactionsState {
    return newState(state, {
        detailedTransactions: { page: 0, pageSize: 0, data: [], totalCount: 0 },
        shortTransactions: { page: 0, pageSize: 0, data: [], totalCount: 0 },
        error: null,
        isLoading: false,
        groupTypes: rehydratedState?.transactions?.groupTypes || [],
    });
}

function getShortListStarted(state: ITransactionsState): ITransactionsState {
    return newState(state, {
        error: null,
        isLoading: true,
        detailedTransactions: { page: 0, pageSize: 0, data: [], totalCount: 0 },
    });
}

function getDefailedListStarted(state: ITransactionsState): ITransactionsState {
    return newState(state, {
        error: null,
        isLoading: true,
        shortTransactions: { page: 0, pageSize: 0, data: [], totalCount: 0 },
    });
}

function getListFailed(
    state: ITransactionsState,
    failed: Failure<IEmpty, Error>
): ITransactionsState {
    return newState(state, { error: failed.error.message, isLoading: false });
}

function getShortListDone(
    state: ITransactionsState,
    { result }: Success<TransactionsShortListRequest, PagedResponse<TransactionsShortItem>>
): ITransactionsState {
    return newState(state, { error: null, isLoading: false, shortTransactions: result });
}

function getDetailedListDone(
    state: ITransactionsState,
    { result }: Success<TransactionsDetailedListRequest, PagedResponse<TransactionsDetailedItem>>
): ITransactionsState {
    return newState(state, { error: null, isLoading: false, detailedTransactions: result });
}

function getGroupTypesDone(
    state: ITransactionsState,
    { result }: Success<void, TransactionsGroupType[]>
): ITransactionsState {
    return newState(state, { groupTypes: result });
}

export const transactionsReducer = reducerWithInitialState(TransactionsInitialState)
    .case(CoreActions.rehydrate, rehydrateHandler)

    .case(TransactionsActions.getShortList.started, getShortListStarted)
    .case(TransactionsActions.getShortList.done, getShortListDone)
    .case(TransactionsActions.getShortList.failed, getListFailed)

    .case(TransactionsActions.getGroupTypes.done, getGroupTypesDone)

    .case(TransactionsActions.getDetailedList.started, getDefailedListStarted)
    .case(TransactionsActions.getDetailedList.done, getDetailedListDone)
    .case(TransactionsActions.getDetailedList.failed, getListFailed);
