import React, { FC, memo, useCallback } from "react";
import { RouteProps } from "react-router";
import { Redirect, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { authSelectors } from "../../modules/auth/authSelectors";

interface IProps extends RouteProps {
    Component: any;
    reverse?: boolean;
}

export const AuthenticatedRoute: FC<IProps> = memo(
    ({ Component, reverse, ...rest }): JSX.Element => {
        const tokenExists = useSelector(authSelectors.isAuthenticated);

        const renderRoute = useCallback(
            (props) => {
                if (reverse) {
                    return !tokenExists ? <Component {...props} /> : <Redirect to={"/"} />;
                } else {
                    return tokenExists ? <Component {...props} /> : <Redirect to={"/login"} />;
                }
            },
            [tokenExists, reverse]
        );

        return <Route {...rest} render={renderRoute} />;
    }
);
