import { AuthInitialState, IAuthState } from "../../modules/auth/authState";
import { ISystemState, SystemInitialState } from "../../modules/system/systemState";
import { BusesInitialState, IBusesState } from "../../modules/buses/busesState";
import { EntitiesInitialState, IEntitiesState } from "../../modules/entities/entitiesState";
import { IRoutesState, RoutesInitialState } from "../../modules/routes/routesState";
import {
    ITransactionsState,
    TransactionsInitialState,
} from "../../modules/transactions/transactionsState";
import { ISchedulesState, ScheduledInitialState } from "../../modules/schedules/schedulesState";
import { IRoundsState, RoundsInitialState } from "../../modules/rounds/roundsState";

export interface IAppState {
    auth: IAuthState;
    system: ISystemState;
    buses: IBusesState;
    entities: IEntitiesState;
    routes: IRoutesState;
    transactions: ITransactionsState;
    schedules: ISchedulesState;
    rounds: IRoundsState;
}

export function getAppInitialState(): IAppState {
    return {
        auth: AuthInitialState,
        system: SystemInitialState,
        buses: BusesInitialState,
        entities: EntitiesInitialState,
        routes: RoutesInitialState,
        transactions: TransactionsInitialState,
        schedules: ScheduledInitialState,
        rounds: RoundsInitialState,
    };
}
