import { PagedResponse } from "../../core/api/dto/PagedResponse.g";
import { Route } from "../../core/api/dto/Route.g";
import { DetailedRoute } from "../../core/api/dto/DetailedRoute.g";

export interface IRoutesState {
    isLoading: boolean;
    list: PagedResponse<Route>;
    route: DetailedRoute | null;
    error: string | null;
}

export const RoutesInitialState: IRoutesState = {
    isLoading: false,
    list: { data: [], page: 0, pageSize: 0, totalCount: 0 },
    error: null,
    route: null,
};
