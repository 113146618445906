import { reducerWithInitialState } from "typescript-fsa-reducers";
import { Failure, Success } from "typescript-fsa";
import { newState } from "../../common/newState";
import { IEmpty } from "../../common/empty";
import { EntitiesInitialState, IEntitiesState } from "./entitiesState";
import { EntitiesActions } from "./entitiesActions";
import { DictionaryObject } from "../../core/api/dto/DictionaryObject.g";
import { BusStopsRequest } from "../../core/api/dto/BusStopsRequest.g";
import { AddBusStopRequest } from "../../core/api/dto/AddBusStopRequest.g";
import { BusStop } from "../../core/api/dto/BusStop.g";
import { BusStopRequest } from "../../core/api/dto/BusStopRequest.g ";
import { EditBusStopRequest } from "../../core/api/dto/EditBusStopRequest.g";
import { PagedResponse } from "../../core/api/dto/PagedResponse.g";
import { Company } from "../../core/api/dto/Company.g";

function loadingStarted(state: IEntitiesState): IEntitiesState {
    return newState(state, { isLoading: true });
}

function loadingFailed(state: IEntitiesState, failed: Failure<IEmpty, Error>): IEntitiesState {
    return newState(state, { isLoading: false, error: failed.error.message });
}

function loadingBusStopsDone(
    state: IEntitiesState,
    { result }: Success<BusStopsRequest, PagedResponse<BusStop>>
): IEntitiesState {
    return newState(state, { isLoading: false, busStops: result });
}

function loadingBusStopDone(
    state: IEntitiesState,
    { result }: Success<BusStopRequest, BusStop>
): IEntitiesState {
    return newState(state, { isLoading: false, busStop: result });
}

function addingBusStopDone(
    state: IEntitiesState,
    { result }: Success<AddBusStopRequest, BusStop>
): IEntitiesState {
    const busStopsList = [...state.busStops.data];
    busStopsList.push(result);

    return newState(state, {
        isLoading: false,
        busStops: { ...state.busStops, data: busStopsList },
    });
}

function editingBusStopDone(
    state: IEntitiesState,
    { params, result }: Success<EditBusStopRequest, BusStop>
): IEntitiesState {
    const busStopsList = [...state.busStops.data];
    const busStopIndex = busStopsList.findIndex((item) => item.id === params.busStopId);

    if (busStopIndex !== -1) {
        busStopsList.splice(busStopIndex, 1, result);
    }

    return newState(state, {
        isLoading: false,
        busStops: { ...state.busStops, data: busStopsList },
    });
}

function loadingBusColorsDone(
    state: IEntitiesState,
    { result }: Success<IEmpty, DictionaryObject[]>
): IEntitiesState {
    return newState(state, { isLoading: false, busColors: result });
}

function loadingBusModelsDone(
    state: IEntitiesState,
    { result }: Success<IEmpty, DictionaryObject[]>
): IEntitiesState {
    return newState(state, { isLoading: false, busModels: result });
}

function loadingRouteTypesDone(
    state: IEntitiesState,
    { result }: Success<IEmpty, DictionaryObject[]>
): IEntitiesState {
    return newState(state, { isLoading: false, routeTypes: result });
}

function loadingVehicleTypesDone(
    state: IEntitiesState,
    { result }: Success<IEmpty, DictionaryObject[]>
): IEntitiesState {
    return newState(state, { isLoading: false, vehicleTypes: result });
}

function loadingCompaniesDone(
    state: IEntitiesState,
    { result }: Success<IEmpty, PagedResponse<Company>>
): IEntitiesState {
    return newState(state, { isLoading: false, companies: result });
}

export const entitiesReducer = reducerWithInitialState(EntitiesInitialState)
    .case(EntitiesActions.getBusStops.started, loadingStarted)
    .case(EntitiesActions.getBusStops.done, loadingBusStopsDone)
    .case(EntitiesActions.getBusStops.failed, loadingFailed)

    .case(EntitiesActions.getBusStop.started, loadingStarted)
    .case(EntitiesActions.getBusStop.done, loadingBusStopDone)
    .case(EntitiesActions.getBusStop.failed, loadingFailed)

    .case(EntitiesActions.addBusStop.started, loadingStarted)
    .case(EntitiesActions.addBusStop.done, addingBusStopDone)
    .case(EntitiesActions.addBusStop.failed, loadingFailed)

    .case(EntitiesActions.editBusStop.started, loadingStarted)
    .case(EntitiesActions.editBusStop.done, editingBusStopDone)
    .case(EntitiesActions.editBusStop.failed, loadingFailed)

    .case(EntitiesActions.getBusColors.started, loadingStarted)
    .case(EntitiesActions.getBusColors.done, loadingBusColorsDone)
    .case(EntitiesActions.getBusColors.failed, loadingFailed)

    .case(EntitiesActions.getBusModels.started, loadingStarted)
    .case(EntitiesActions.getBusModels.done, loadingBusModelsDone)
    .case(EntitiesActions.getBusModels.failed, loadingFailed)

    .case(EntitiesActions.getRouteTypes.started, loadingStarted)
    .case(EntitiesActions.getRouteTypes.done, loadingRouteTypesDone)
    .case(EntitiesActions.getRouteTypes.failed, loadingFailed)

    .case(EntitiesActions.getVehicleTypes.started, loadingStarted)
    .case(EntitiesActions.getVehicleTypes.done, loadingVehicleTypesDone)
    .case(EntitiesActions.getVehicleTypes.failed, loadingFailed)

    .case(EntitiesActions.getCompanies.started, loadingStarted)
    .case(EntitiesActions.getCompanies.done, loadingCompaniesDone)
    .case(EntitiesActions.getCompanies.failed, loadingFailed);
