import React, { Component, Suspense } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import "./scss/style.scss";
import { AuthenticatedRoute } from "./core/router/AuthenticadedRoute";
import { Provider } from "react-redux";
import { configureStore, MigrateStoreMode } from "./core/store/configureStore";
import { PersistGate } from "redux-persist/integration/react";
import { Store } from "redux";
import { IAppState } from "./core/store/appState";
import { IEmpty } from "./common/empty";
import { EventNames, eventRegister } from "./common/eventRegister";
import { BaseRequest } from "./core/api/BaseRequest";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

const loading = (
    <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse" />
    </div>
);

// Containers
const TheLayout = React.lazy(async () => import("./containers/TheLayout")) as any;

// Pages
const AuthPage = React.lazy(async () => import("./modules/auth/AuthPage"));
const Page404 = React.lazy(async () => import("./core/router/Page404")) as any;
const Page500 = React.lazy(async () => import("./core/router/Page500")) as any;

class App extends Component {
    private store!: Store<IAppState>;
    private persistor: any;
    private logoutListenerId!: string;

    constructor(props: IEmpty) {
        super(props);
        this.logout = this.logout.bind(this);
        this.onStoreConfigured = this.onStoreConfigured.bind(this);
        this.createStore(MigrateStoreMode.resetStatePreserveToken);
    }

    componentDidMount(): void {
        this.logoutListenerId = eventRegister.addEventListener(EventNames.logout, this.logout);
    }

    componentWillUnmount(): void {
        eventRegister.removeEventListener(this.logoutListenerId);
    }

    render() {
        return (
            <Provider store={this.store}>
                <PersistGate loading={null} persistor={this.persistor}>
                    <HashRouter>
                        <DndProvider backend={HTML5Backend}>
                            <ToastContainer />
                            <Suspense fallback={loading}>
                                <Switch>
                                    <AuthenticatedRoute
                                        path={"/login"}
                                        Component={AuthPage}
                                        reverse={true}
                                    />
                                    <AuthenticatedRoute path={"/"} Component={TheLayout} />
                                    <Route
                                        exact
                                        path="/404"
                                        render={(props) => <Page404 {...props} />}
                                    />
                                    <Route
                                        exact
                                        path="/500"
                                        render={(props) => <Page500 {...props} />}
                                    />
                                </Switch>
                            </Suspense>
                        </DndProvider>
                    </HashRouter>
                </PersistGate>
            </Provider>
        );
    }

    private logout(): void {
        this.createStore(MigrateStoreMode.purge);
        window.location.assign("/");
    }

    private createStore(mode: MigrateStoreMode): void {
        const { store, persistor } = configureStore(this.onStoreConfigured, { migrateMode: mode });
        this.store = store;
        this.persistor = persistor;
    }

    private onStoreConfigured(): void {
        BaseRequest.globalOptions = {
            getToken: (): string | null => this.store.getState().auth.token,
        };
    }
}
// eslint-disable-next-line import/no-default-export
export default App;
