export interface IAuthState {
    token: string | null;
    isAuthenticating: boolean;
    error: string | null;
}

export const AuthInitialState: IAuthState = {
    token: null,
    isAuthenticating: false,
    error: null,
};
