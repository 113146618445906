import { reducerWithInitialState } from "typescript-fsa-reducers";
import { newState } from "../../common/newState";
import { CoreActions } from "../../core/store/coreActions";
import { ISystemState, SystemInitialState } from "./systemState";
import { SystemActions } from "./systemActions";
import { SidebarValue } from "../../common/interfaces/sidebarValue";

function rehydrateHandler(state: ISystemState): ISystemState {
    return newState(state, { sidebar: "responsive" });
}

function setSidebarHandler(state: ISystemState, sidebar: SidebarValue): ISystemState {
    return newState(state, { sidebar });
}

export const systemReducer = reducerWithInitialState(SystemInitialState)
    .case(CoreActions.rehydrate, rehydrateHandler)
    .case(SystemActions.setSidebar, setSidebarHandler);
