import { createStore, Store } from "redux";
import { persistCombineReducers, PersistConfig, persistStore } from "redux-persist";
import { buildStack } from "redux-stack";
import { newState } from "../../common/newState";
import { getAppInitialState, IAppState } from "./appState";
import { promiseInit } from "./init/promiseInit";
import { thunkInit } from "./init/thunkInit";
import storage from "redux-persist/lib/storage";
import { ignorePromise } from "../../common/utils/ignorePromise";
import { createMainReducer } from "./createMainReducer";
import { reduxLoggerInit } from "./init/loggerInit";
import { roundsStateTransform } from "../../modules/rounds/roundsStateTransform";

export enum MigrateStoreMode {
    none = "none",
    purge = "purge",
    resetStatePreserveToken = "resetStatePreserveToken",
    resetStateWithToken = "resetStateWithToken",
}

async function tryProcessStateUpdate(state: IAppState): Promise<IAppState> {
    const AppInitialState = getAppInitialState();

    if (state == null) {
        return Promise.resolve(AppInitialState);
    }

    return Promise.resolve(Object.assign({}, AppInitialState, state));
}

async function resetStateWithTokenFunc(state: IAppState): Promise<IAppState> {
    const AppInitialState = getAppInitialState();
    const nState: IAppState = state;

    if (nState == null) {
        return Promise.resolve(AppInitialState);
    }

    return Promise.resolve(
        newState(AppInitialState, {
            auth: {
                token: nState.auth.token,
                isAuthenticating: false,
                error: null,
            },
        })
    );
}

async function resetState(state: IAppState): Promise<IAppState> {
    const AppInitialState = getAppInitialState();

    if (state == null) {
        return Promise.resolve(AppInitialState);
    }

    return Promise.resolve(
        newState(AppInitialState, {
            auth: {
                token: state.auth.token,
                isAuthenticating: false,
                error: null,
            },
        })
    );
}

export function configureStore(
    callback: () => void,
    options: {
        migrateMode: MigrateStoreMode;
    }
): { store: Store<IAppState>; persistor: any } {
    const { enhancer } = buildStack([thunkInit, promiseInit, reduxLoggerInit]);
    const migrateStore: Map<MigrateStoreMode, (state: IAppState) => Promise<IAppState>> = new Map();
    migrateStore.set(MigrateStoreMode.purge, async () => Promise.resolve(getAppInitialState()));
    migrateStore.set(MigrateStoreMode.none, tryProcessStateUpdate);
    migrateStore.set(MigrateStoreMode.resetStatePreserveToken, resetState);
    migrateStore.set(MigrateStoreMode.resetStateWithToken, resetStateWithTokenFunc);

    const persistConfig: PersistConfig<IAppState> = {
        key: "root",
        storage,
        migrate: migrateStore.get(options.migrateMode)! as any,
        transforms: [roundsStateTransform],
        timeout: 0,
        blacklist: [],
    };
    const combinedReducer = createMainReducer((reducers: any) =>
        persistCombineReducers<any, any>(persistConfig, reducers)
    );
    const store = createStore(combinedReducer, enhancer);
    const persistor = persistStore(store, undefined, callback);
    if (options.migrateMode === MigrateStoreMode.purge) {
        ignorePromise(persistor.purge());
    }

    return { store, persistor };
}
