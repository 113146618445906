import { assertNotNull } from "../../common/utils/assertNotNull";
import { AbortHub } from "../../common/helpers/abortHub";
import { getLocalizedError } from "../../common/helpers/errorHelper";
import { EventNames, eventRegister } from "../../common/eventRegister";
import { IHttpError } from "../../common/interfaces/httpError";
import { HttpError } from "../HttpError";
import { showNotification } from "../../common/helpers/notificationHelper";

export interface IRequestOptions {
    getToken: () => string | null;
}

export class BaseRequest {
    static globalOptions: IRequestOptions;

    static handleError = async (error: Record<string, any>) => {
        let err = {
            Status: error.status,
            Message: error.message || error.statusText || "",
        } as IHttpError;

        if (error.message !== "Fetch is aborted") {
            try {
                const notificationMessage = getLocalizedError(err.Message, err.Status);
                showNotification(
                    notificationMessage.type,
                    notificationMessage.message,
                    notificationMessage.description
                );
            } catch (e) {
                err = { ...err, Message: error.statusText || error, Type: error.type };
            }
        }
        throw new HttpError(err);
    };

    protected baseurl = "";

    async fetch(url: string, config: Record<string, any>): Promise<any> {
        const configExists = config !== undefined;
        const customHeaders = configExists && config.headers !== undefined ? config.headers : {};

        const abort = configExists ? config.abort : false;
        delete config.abort;

        const headers = this.addTokenToHeaders({
            "Accept": "application/json",
            "Content-Type": "application/json",
            ...customHeaders,
        });

        const signal = AbortHub.instance.getSignal(url, abort);
        console.log(process.env.REACT_APP_SERVER_URL, url)
        return fetch(
            process.env.REACT_APP_SERVER_URL + url,
            Object.assign({ headers, signal }, config as any)
        ).then((response) => {
            if (!response.status || response.status < 200 || response.status >= 300) {
                if (response.status === 401) {
                    eventRegister.emitEvent(EventNames.logout);
                }
                throw response;
            }

            return response;
        });
    }

    protected get options(): IRequestOptions {
        return (
            BaseRequest.globalOptions || {
                getToken: (): string | null => null,
            }
        );
    }

    protected addTokenToHeaders(headers: object): object {
        const userToken = this.options.getToken();

        if (userToken != null) {
            return { ...headers, Authorization: `Bearer ${userToken}` };
        } else {
            return headers;
        }
    }

    protected q(params: { [key: string]: string | number | boolean | Date | null }): string {
        const query = Object.keys(params)
            .filter((k) => params[k] != null)
            .map((k) => `${k}=${encodeURIComponent(assertNotNull(params[k]).toString())}`)
            .join("&");

        return query ? `?${query}` : "";
    }

    getObjectFlatter(): (node: Record<string, any>, parentName?: string) => string {
        const flatObject: Record<string, any> = {};

        return function objectToFlat(node: Record<string, any>, parentName = ""): string {
            Object.keys(node).forEach((key) => {
                const newKey = parentName ? parentName + "." + key : key;
                // eslint-disable-next-line no-unused-expressions
                node[key] instanceof Object
                    ? objectToFlat(node[key], newKey)
                    : (flatObject[newKey] = node[key]);
            });

            return Object.keys(flatObject).reduce((q, key) => {
                return flatObject[key] ? `${q}${!!q ? "&" : "?"}${key}=${flatObject[key]}` : q;
            }, "");
        };
    }
}
