import { Bus } from "../../core/api/dto/Bus.g";
import { BusesListResponse } from "../../core/api/dto/BusesListResponse.g";

export interface IBusesState {
    isLoading: boolean;
    list: BusesListResponse;
    bus: Bus | null;
    editingBusId?: number;
    error: string | null;
}

export const BusesInitialState: IBusesState = {
    isLoading: false,
    list: { data: [], page: 0, pageSize: 0, totalCount: 0 },
    bus: null,
    editingBusId: undefined,
    error: null,
};
