import { DictionaryObject } from "../../core/api/dto/DictionaryObject.g";
import { BusStop } from "../../core/api/dto/BusStop.g";
import { PagedResponse } from "../../core/api/dto/PagedResponse.g";
import { Company } from "../../core/api/dto/Company.g";

export interface IEntitiesState {
    isLoading: boolean;
    busColors: DictionaryObject[];
    busModels: DictionaryObject[];
    routeTypes: DictionaryObject[];
    vehicleTypes: DictionaryObject[];
    busStops: PagedResponse<BusStop>;
    busStop: BusStop | null;
    companies: PagedResponse<Company>;
    error: string | null;
}

export const EntitiesInitialState: IEntitiesState = {
    isLoading: false,
    busColors: [],
    busModels: [],
    routeTypes: [],
    vehicleTypes: [],
    busStops: { data: [], page: 0, pageSize: 0, totalCount: 0 },
    busStop: null,
    companies: { data: [], page: 0, pageSize: 0, totalCount: 0 },
    error: null,
};
