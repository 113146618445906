import { TransactionsGroupType } from "../../core/api/dto/TransactionsGroupType.g";
import { PagedResponse } from "../../core/api/dto/PagedResponse.g";
import { TransactionsDetailedItem } from "../../core/api/dto/TransactionsDetailedItem.g";
import { TransactionsShortItem } from "../../core/api/dto/TransactionsShortItem.g";

export interface ITransactionsState {
    shortTransactions: PagedResponse<TransactionsShortItem>;
    detailedTransactions: PagedResponse<TransactionsDetailedItem>;
    groupTypes: TransactionsGroupType[];
    isLoading: boolean;
    error: string | null;
}

export const TransactionsInitialState: ITransactionsState = {
    shortTransactions: { page: 0, pageSize: 0, data: [], totalCount: 0 },
    detailedTransactions: { page: 0, pageSize: 0, data: [], totalCount: 0 },
    groupTypes: [],
    isLoading: false,
    error: null,
};
