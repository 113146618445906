import { createSelector } from "reselect";
import { IAppState } from "../../core/store/appState";
import { IAuthState } from "./authState";

const state = ({ auth }: IAppState): IAuthState => auth;

export const authSelectors = {
    isAuthenticated: createSelector(state, ({ token }) => token != null),
    isAuthenticating: createSelector(state, ({ isAuthenticating }) => isAuthenticating),
};
