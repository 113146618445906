import { IHttpError } from "../common/interfaces/httpError";

export class HttpError extends Error {
    constructor(error: IHttpError) {
        super(error.Message);
        this.name = "HttpError";
        this.Message = error.Message;
        this.Data = error.Data;
        this.StackTrace = error.StackTrace;
        this.Type = error.Type;
        this.Status = error.Status;
    }
    Message: string;
    Type: string;
    Data?: object;
    StackTrace?: string;
    Status?: number;
}
