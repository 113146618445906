/*
 * required polyfills
 */
import "core-js";
import "core-js/features/set/map";
import "core-js/features/map";

/** IE9, IE10 and IE11 requires all of the following polyfills. **/
// import "core-js/es6/symbol"
// import "core-js/es6/object"
// import "core-js/es6/function"
// import "core-js/es6/parse-int"
// import "core-js/es6/parse-float"
// import "core-js/es6/number"
// import "core-js/es6/math"
// import "core-js/es6/string"
// import "core-js/es6/date"
// import "core-js/es6/array"
// import "core-js/es6/regexp"
// import "core-js/es6/map"
// import "core-js/es6/weak-map"
// import "core-js/es6/set"
// import "core-js/es7/object"

/** IE10 and IE11 requires the following for the Reflect API. */
// import "core-js/es6/reflect"

/** Evergreen browsers require these. **/
// Used for reflect-metadata in JIT. If you use AOT (and only Angular decorators), you can remove.
// import "core-js/es7/reflect"

// CustomEvent() constructor functionality in IE9, IE10, IE11
(function (): any {
    if (typeof window.CustomEvent === "function") {
        return false;
    }

    function CustomEvent(event: any, params: any) {
        params = params != null || { bubbles: false, cancelable: false, detail: undefined };
        const evt = document.createEvent("CustomEvent");
        evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);

        return evt;
    }

    CustomEvent.prototype = (window.Event as any).prototype(
        window as any
    ).CustomEvent = CustomEvent;
})();
