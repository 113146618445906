import { IRoutesState, RoutesInitialState } from "./routesState";
import { reducerWithInitialState } from "typescript-fsa-reducers";
import { Failure, Success } from "typescript-fsa";
import { newState } from "../../common/newState";
import { IEmpty } from "../../common/empty";
import { RoutesActions } from "./routesActions";
import { RoutesListRequest } from "../../core/api/dto/RoutesListRequest.g";
import { PagedResponse } from "../../core/api/dto/PagedResponse.g";
import { Route } from "../../core/api/dto/Route.g";
import { RouteRequest } from "../../core/api/dto/RouteRequest.g";
import { DetailedRoute } from "../../core/api/dto/DetailedRoute.g";
import { RouteBusStopsRequest } from "../../core/api/dto/RouteBusStopsRequest.g";

function commonStarted(state: IRoutesState): IRoutesState {
    return newState(state, { isLoading: true });
}

function commonDone(state: IRoutesState): IRoutesState {
    return newState(state, { isLoading: false, error: null });
}

function commonFailed(state: IRoutesState, failed: Failure<IEmpty, Error>): IRoutesState {
    return newState(state, { isLoading: false, error: failed.error.message });
}

function listDone(
    state: IRoutesState,
    { result }: Success<RoutesListRequest, PagedResponse<Route>>
): IRoutesState {
    return newState(state, { isLoading: false, list: result });
}

function routeDone(
    state: IRoutesState,
    { params, result }: Success<RouteRequest, DetailedRoute>
): IRoutesState {
    return newState(state, { isLoading: false, route: result });
}

function addDone(state: IRoutesState, { result }: Success<IEmpty, Route>): IRoutesState {
    const list = { ...state.list };
    const listData = [...list.data];
    listData.unshift(result);

    return newState(state, { isLoading: false, list });
}

function editDone(state: IRoutesState, { result }: Success<IEmpty, Route>): IRoutesState {
    const list = { ...state.list };
    const listData = [...list.data];
    const itemIndex = listData.findIndex((item) => item.id === result.id);

    if (itemIndex !== -1) {
        listData[itemIndex] = result;
    } else {
        listData.unshift(result);
    }

    return newState(state, { isLoading: false, list });
}

function setBusStopsDone(
    state: IRoutesState,
    { result }: Success<RouteBusStopsRequest, DetailedRoute>
): IRoutesState {
    return newState(state, { isLoading: false, route: result });
}

export const routesReducer = reducerWithInitialState(RoutesInitialState)
    .case(RoutesActions.list.started, commonStarted)
    .case(RoutesActions.list.done, listDone)
    .case(RoutesActions.list.failed, commonFailed)

    .case(RoutesActions.getRoute.started, commonStarted)
    .case(RoutesActions.getRoute.done, routeDone)
    .case(RoutesActions.getRoute.failed, commonFailed)

    .case(RoutesActions.getById.started, commonStarted)
    .case(RoutesActions.getById.done, editDone)
    .case(RoutesActions.getById.failed, commonFailed)

    .case(RoutesActions.add.started, commonStarted)
    .case(RoutesActions.add.done, addDone)
    .case(RoutesActions.add.failed, commonFailed)

    .case(RoutesActions.edit.started, commonStarted)
    .case(RoutesActions.edit.done, editDone)
    .case(RoutesActions.edit.failed, commonFailed)

    .case(RoutesActions.setToCompany.started, commonStarted)
    .case(RoutesActions.setToCompany.done, commonDone)
    .case(RoutesActions.setToCompany.failed, commonFailed)

    .case(RoutesActions.setPolyline.started, commonStarted)
    .case(RoutesActions.setPolyline.done, commonDone)
    .case(RoutesActions.setPolyline.failed, commonFailed)

    .case(RoutesActions.setBusStops.started, commonStarted)
    .case(RoutesActions.setBusStops.done, setBusStopsDone)
    .case(RoutesActions.setBusStops.failed, commonFailed);
