import { Round } from "../../core/api/dto/Round.g";

export interface IRoundsState {
    rounds: Map<string, Round[]>;
    isLoading: boolean;
    error: string | null;
}

export const RoundsInitialState: IRoundsState = {
    rounds: new Map(),
    isLoading: false,
    error: null,
};
