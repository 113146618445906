import { actionCreator } from "../../core/store/actionCreator";
import { BusesListResponse } from "../../core/api/dto/BusesListResponse.g";
import { BusesListRequest } from "../../core/api/dto/BusesListRequest.g";
import { DeleteBusRequest } from "../../core/api/dto/DeleteBusRequest";
import { SuccessResponse } from "../../core/api/dto/SuccessResponse.g";
import { AddBusRequest } from "../../core/api/dto/AddBusRequest.g";
import { Bus } from "../../core/api/dto/Bus.g";
import { EditBusRequest } from "../../core/api/dto/EditBusRequest.g";
import { ChangeBusStateRequest } from "../../core/api/dto/ChangeBusStateRequest.g";
import { TransferBusRequest } from "../../core/api/dto/TransferBusRequest.g";
import { BusRequest } from "../../core/api/dto/BusRequest.g";
import { SetToRouteRequest } from "../../core/api/dto/SetToRouteRequest.g";

export class BusesActions {
    static getBuses = actionCreator.async<BusesListRequest, BusesListResponse, Error>(
        "Buses/GET_BUSES"
    );

    static getBus = actionCreator.async<BusRequest, Bus, Error>("Buses/GET_BUS");

    static delete = actionCreator.async<DeleteBusRequest, SuccessResponse, Error>("Buses/DELETE");

    static add = actionCreator.async<AddBusRequest, Bus, Error>("Buses/ADD");

    static edit = actionCreator.async<EditBusRequest, Bus, Error>("Buses/EDIT");

    static changeBusState = actionCreator.async<ChangeBusStateRequest, Bus, Error>(
        "Buses/CHANGE_STATE"
    );

    static transfer = actionCreator.async<TransferBusRequest, Bus, Error>("Buses/TRANSFER");

    static setToRoute = actionCreator.async<SetToRouteRequest, Bus, Error>("Buses/SET_TO_ROUTE");
}
