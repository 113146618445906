import { actionCreator } from "../../core/store/actionCreator";

import { DictionaryObject } from "../../core/api/dto/DictionaryObject.g";
import { CompaniesListRequest } from "../../core/api/dto/CompaniesListRequest.g";
import { BusStop } from "../../core/api/dto/BusStop.g";
import { AddBusStopRequest } from "../../core/api/dto/AddBusStopRequest.g";
import { BusStopRequest } from "../../core/api/dto/BusStopRequest.g ";
import { EditBusStopRequest } from "../../core/api/dto/EditBusStopRequest.g";
import { PagedResponse } from "../../core/api/dto/PagedResponse.g";
import { Company } from "../../core/api/dto/Company.g";

export class EntitiesActions {
    static getBusStops = actionCreator.async<{}, PagedResponse<BusStop>, Error>(
        "Entities/GET_BUS_STOPS"
    );

    static getBusStop = actionCreator.async<BusStopRequest, BusStop, Error>(
        "Entities/GET_BUS_STOP"
    );

    static addBusStop = actionCreator.async<AddBusStopRequest, BusStop, Error>(
        "Entities/ADD_BUS_STOP"
    );

    static editBusStop = actionCreator.async<EditBusStopRequest, BusStop, Error>(
        "Entities/EDIT_BUS_STOP"
    );

    static getBusColors = actionCreator.async<{}, DictionaryObject[], Error>(
        "Entities/GET_BUS_COLORS"
    );

    static getBusModels = actionCreator.async<{}, DictionaryObject[], Error>(
        "Entities/GET_BUS_MODELS"
    );

    static getCompanies = actionCreator.async<CompaniesListRequest, PagedResponse<Company>, Error>(
        "Entities/GET_COMPANIES"
    );

    static getRouteTypes = actionCreator.async<{}, DictionaryObject[], Error>(
        "Entities/GET_ROUTE_TYPES"
    );

    static getVehicleTypes = actionCreator.async<{}, DictionaryObject[], Error>(
        "Entities/GET_VEHICLE_TYPES"
    );
}
