import { actionCreator } from "../../core/store/actionCreator";
import { RoundsAddRequest } from "../../core/api/dto/RoundsAddRequest.g";
import { Round } from "../../core/api/dto/Round.g";
import { RoundsGetRequest } from "../../core/api/dto/RoundsGetRequest.g";
import { RoundEditRequest } from "../../core/api/dto/RoundEditRequest.g";
import { RoundsGetByDirectionRequest } from "../../core/api/dto/RoundsGetByDirectionRequest.g";

export class RoundsActions {
    static edit = actionCreator.async<RoundEditRequest, Round, Error>("Rounds/EDIT");
    static get = actionCreator.async<RoundsGetRequest, Round[], Error>("Rounds/GET");
    static add = actionCreator.async<RoundsAddRequest, Round, Error>("Rounds/ADD");
    static getByDirection = actionCreator.async<RoundsGetByDirectionRequest, Round[], Error>(
        "Rounds/GET_BY_DIRECTION"
    );
}
