import { PagedResponse } from "../../core/api/dto/PagedResponse.g";
import { ScheduleRoundItem } from "../../core/api/dto/ScheduleRoundItem.g";

export interface ISchedulesState {
    scheduleRounds: PagedResponse<ScheduleRoundItem>;
    isLoading: boolean;
    error: string | null;
}

export const ScheduledInitialState: ISchedulesState = {
    scheduleRounds: { page: 0, pageSize: 0, data: [], totalCount: 0 },
    isLoading: false,
    error: null,
};
