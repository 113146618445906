import { actionCreator } from "../../core/store/actionCreator";
import { RoutesListRequest } from "../../core/api/dto/RoutesListRequest.g";
import { PagedResponse } from "../../core/api/dto/PagedResponse.g";
import { Route } from "../../core/api/dto/Route.g";
import { RouteAddRequest } from "../../core/api/dto/RouteAddRequest.g";
import { RouteToCompanyRequest } from "../../core/api/dto/RouteToCompanyRequest.g";
import { SuccessResponse } from "../../core/api/dto/SuccessResponse.g";
import { RoutePolylineRequest } from "../../core/api/dto/RoutePolylineRequest.g";
import { RouteRequest } from "../../core/api/dto/RouteRequest.g";
import { DetailedRoute } from "../../core/api/dto/DetailedRoute.g";
import { RouteBusStopsRequest } from "../../core/api/dto/RouteBusStopsRequest.g";

export class RoutesActions {
    static list = actionCreator.async<RoutesListRequest, PagedResponse<Route>, Error>(
        "Routes/LIST"
    );
    static getRoute = actionCreator.async<RouteRequest, DetailedRoute, Error>("Routes/GET_ROUTE");
    static add = actionCreator.async<RouteAddRequest, Route, Error>("Routes/ADD");
    static edit = actionCreator.async<Partial<RouteAddRequest>, Route, Error>("Routes/GET");
    static getById = actionCreator.async<number, Route, Error>("Routes/GET_BY_ID");
    static setToCompany = actionCreator.async<RouteToCompanyRequest, SuccessResponse, Error>(
        "Routes/SET_TO_COMPANY"
    );
    static setPolyline = actionCreator.async<RoutePolylineRequest, SuccessResponse, Error>(
        "Routes/SET_POLYLINE"
    );
    static setBusStops = actionCreator.async<RouteBusStopsRequest, DetailedRoute, Error>(
        "Routes/SET_BUS_STOPS"
    );
}
