// eslint-disable-next-line max-classes-per-file
import moment from "moment";
// eslint-disable-next-line import/no-unassigned-import
require("moment/locale/ru");

export class DateStringFormats {
    static simple = "MM.DD.YYYY"; //12.24.2018
    static commonAffairs = "D MMMM, dddd"; //12 февраля, понедельник
    static datePicker = "MMMM, D"; //Март, 17
    static dayAndMonth = "D MMMM"; //12 март
    static dateAndTime = "D.MM в HH:mm"; //22.08 в 04:00
    static date = "D.MM"; //22.08
    static time = "HH:mm"; //09:00
    static year = "YYYY"; //2018
    static shortMonth = "MMM"; //Дек
    static dayNumber = "DD"; //29
    static monthAndYear = "MMM YYYY"; //Янв 2019
    static dayMonthYear = "D MMMM YYYY";
    static full = "YYYY-MM-DD HH:mm:ss";
    static yearDateTime = "YYYY-MM-DD HH:mm";
    static yearMonthDayBySub = "YYYY-MM-DD";
}

export function setInitialMomentData(locale?: string) {
    moment.locale(locale || "ru", {
        calendar: {
            sameDay: "[Today], " + DateStringFormats.time,
            nextDay: "[Tomorrow], " + DateStringFormats.time,
            nextWeek: "[Next], " + DateStringFormats.time,
            lastDay: "[Yesterday], " + DateStringFormats.time,
            lastWeek: "[Last], " + DateStringFormats.time,
            sameElse: DateStringFormats.full,
        },
    });
}

export class DateHelper {
    static fromStringOrNull(obj: string | Date | null | undefined): Date | null {
        if (obj == null) {
            return null;
        }
        if (obj instanceof Date) {
            return obj;
        }

        return new Date(obj);
    }

    static fromString(obj: string | Date): Date {
        if (obj instanceof Date) {
            return obj;
        }

        return new Date(obj);
    }

    static dateFromFormat(date: Date | null | undefined | string, format: string): string {
        let result = "";

        const localDate: Date | null = DateHelper.fromStringOrNull(date);
        if (localDate) {
            result = moment(localDate).format(format).toLowerCase();
        }

        return result;
    }
}
