import { Reducer } from "redux";
import { IAppState } from "./appState";
import { Reducers } from "./Reducers";
import { authReducer } from "../../modules/auth/authReducer";
import { systemReducer } from "../../modules/system/systemReducer";
import { busesReducer } from "../../modules/buses/busesReducer";
import { entitiesReducer } from "../../modules/entities/entitiesReducer";
import { routesReducer } from "../../modules/routes/routesReducer";
import { transactionsReducer } from "../../modules/transactions/transactionsReducer";
import { schedulesReducer } from "../../modules/schedules/schedulesReducer";
import { roundsReducer } from "../../modules/rounds/roundsReducer";

export function createMainReducer(
    combineMethod: (reducers: any) => Reducer<IAppState>
): Reducer<IAppState> {
    const reducers: Reducers<IAppState> = {
        auth: authReducer,
        system: systemReducer,
        buses: busesReducer,
        entities: entitiesReducer,
        routes: routesReducer,
        transactions: transactionsReducer,
        schedules: schedulesReducer,
        rounds: roundsReducer,
    };

    return combineMethod(reducers);
}
