import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";
import { setInitialMomentData } from "../helpers/DateHelper";

i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: "ru",
        load: "languageOnly",
        backend: {
            loadPath: "/locales/{{lng}}/{{ns}}.json",
        },
    })
    .then(function (t) {
        setInitialMomentData(i18n.language);
    });

export function changeLanguage(locale: string) {
    i18n.changeLanguage(locale).then(function (t) {
        setInitialMomentData(locale);
    });
}

interface ILanguageStripItem {
    key: string;
    value: string;
}

export const availableLanguages: string[] = ["ru", "en"];

export const availableStripLanguages: ILanguageStripItem[] = [
    {
        key: "en",
        value: "English",
    },
    {
        key: "ru",
        value: "Русский",
    },
];

// eslint-disable-next-line import/no-default-export
export default i18n;
